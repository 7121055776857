import Tooltip from 'components/Tooltip/Tooltip';
import './Input.scss';

/**
 * Renders an input field and optional label wrapped in a form-group div.
 * 
 * Props: autoFocus, inputClassName, formGroupClassName, errorText, defaultValue, disabled, id, isValid, label, name, onChange, onBlur, placeholder, required, tooltip, tooltipIcon, tooltipContent, type
 */
const Input = (props) => {

  let inputClassName = `input`;
  if (props.inputClassName) {
    inputClassName += ` ${props.inputClassName}`;
  }

  let formGroupClassName = `form-group`;
  if (props.formGroupClassName) {
    formGroupClassName += ` ${props.formGroupClassName}`;
  }
  if (props.isValid === false) {
    formGroupClassName += ` form-group--invalid`;
  }
  if (props.tooltip) {
    formGroupClassName += ` form-group--with-tooltip`;
  }

  return (
    <div className={formGroupClassName}>
      <div className='input-container'>
        {props.label && <label className='label input__label' htmlFor={props.id}>{props.label}</label>}
        <input
          autoFocus={props.autoFocus}
          className={inputClassName}
          defaultValue={props.defaultValue}
          disabled={props.disabled}
          id={props.id}
          name={props.name}
          onChange={props.onChange}
          onKeyDown={props.onKeyDown}
          onBlur={props.onBlur}
          placeholder={props.placeholder}
          required={props.required}
          type={props.type}
          value={props.value}
          min={props.min}
          max={props.max}
          step={props.step}
        />
        {props.errorText && <span className='error-text input__error-text'>{props.errorText}</span>}
      </div>
      {props.tooltip &&
        <Tooltip
          className={props.label ? 'tooltip--in-form-group-with-label' : ''}
          icon={props.tooltipIcon}
          content={props.tooltipContent}
        />
      }
    </div>
  )
};

export default Input;