import './Version.scss';

/**
 * Component for rendering a the current version.
 * 
 * Props: none
 */
const Version = () => {
  return (
    <div className='version'>
      v1.0.1
    </div>
  )
};

export default Version;
